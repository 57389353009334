define('dickens/routes/notfound', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var matches = /(.*).html$/.exec(params.path);
      if (matches != null) {
        return this.transitionTo(matches[1]);
      }
      return this.transitionTo('index');
    }
  });
});