define('dickens/routes/application', ['exports', 'webfontloader'], function (exports, _webfontloader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MODEL = {
    'nav': [{ link: 'index', text: 'Home' }, { link: 'about', text: 'About\xA0Us' }, { link: 'performance', text: 'Performance\xA0Suggestions' }, { link: 'pictures', text: 'Pictures' }, { link: 'music', text: 'Music' }, { link: 'rates', text: 'Rates' }, { link: 'press', text: 'Press' }, { link: 'auditions', text: 'Auditions' }],
    'year': new Date().getFullYear()
  };

  exports.default = Ember.Route.extend({
    model: function model() {
      return new Ember.RSVP.Promise(function (resolve) {
        if (typeof _webfontloader.WebFont === 'undefined') {
          return resolve(MODEL);
        }
        return _webfontloader.WebFont.on('active', function () {
          resolve(MODEL);
        });
      });
    }
  });
});