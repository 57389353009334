define('dickens/routes/press/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [{ link: 'press.2014cbs', text: '2014 &mdash; CBS Sunday Morning', blurb: 'A television segment featuring us' }, { link: 'press.2013sketcher', text: '2013 &mdash; Seattle Times', blurb: 'A short article and artist’s rendering of one of our quartets' }, { link: 'press.2003seattlepi', text: '2003 &mdash; Seattle Post-Intelligencer', blurb: 'A feature article about being a member of our cast' }];
    }
  });
});