define('dickens/router', ['exports', 'dickens/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    notifyGoogleAnalytics: function () {
      if (window.ga) {
        return window.ga('send', 'pageview', {
          'page': this.get('url'),
          'title': this.get('url')
        });
      }
    }.on('didTransition')
  });

  Router.map(function () {
    this.route('about');
    this.route('performance');
    this.route('music');
    this.route('rates');
    this.route('auditions');
    this.route('pictures');
    this.route('press', function () {
      this.route('2003seattlepi');
      this.route('2013sketcher');
      this.route('2014cbs');
    });

    this.route('forms', function () {
      this.route('potentialcaroler');
    });

    this.route('notfound', { path: '*path' });
  });

  exports.default = Router;
});