define('dickens/routes/pictures', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [[{ image: '/assets/images/The Dickens Carolers of Seattle web-1.jpg', caption: '2014 Quartet 4 sings for a family in the lobby of Safeco Plaza' }, { image: '/assets/images/The Dickens Carolers of Seattle web-2.jpg', caption: '2015 Quartet 2 taking requests from diners at The Sorrento Hotel' }], [{ image: '/assets/images/The Dickens Carolers of Seattle web-3.jpg', caption: '2008 Quartet 10 after a family party at a client\'s home' }, { image: '/assets/images/The Dickens Carolers of Seattle web-4.jpg', caption: '2016 Quartet 8 finds a silly moment in "The Wassail Song"' }], [{ image: '/assets/images/The Dickens Carolers of Seattle web-5.jpg', caption: '2010 Quartet 1 greeting guests as they arrive at a black tie event' }, { image: '/assets/images/The Dickens Carolers of Seattle web-6.jpg', caption: '2015 Quartet 7 stops for a photo before boarding an Argosy Christmas Ship' }]];
    }
  });
});