define("dickens/routes/performance", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return ["Caroling at the door to welcome your guests", "A special touch at family gatherings", "Strolling through a reception or cocktail hour to create a holiday atmosphere", "A short concert as part of your meeting or banquet program", "Strolling table-to-table during or after dinner or dessert", "A surprise for your employees or friends", "An elegant gift for your important accounts", "A festive touch for your holiday shoppers"];
    }
  });
});