define('dickens/components/initial-letter', ['exports', 'ember-cli-dropcap/components/drop-cap'], function (exports, _dropCap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dropCap.default.extend({
    classNames: ['dropcap'],
    heightInLines: 2,
    baselinePos: 1
  });
});